import moment from 'moment-timezone';
import { BaseParser } from './base';
import { VictorCurrencies } from 'fortune-client';
import { ParsedQuoteLeg, ParsedQuotePrice, ParsedQuoteTextItem } from './types';

interface TableHeader {
  date: ParsedQuoteTextItem | null;
  flight: ParsedQuoteTextItem | null;
  from: ParsedQuoteTextItem | null;
  departure: ParsedQuoteTextItem | null;
  to: ParsedQuoteTextItem | null;
  arrival: ParsedQuoteTextItem | null;
  duration: ParsedQuoteTextItem | null;
  pax: ParsedQuoteTextItem | null;
}

export class AirXParser extends BaseParser {
  scale = 2;
  legHeight = 35;

  async getAircraft() {
    const textItem = this.getTextItem('Aircraft:');
    if (!textItem) return this.getAircraftResult(null, null);
    const nearItems = this.getTextItemOnSameLine(textItem);
    const width = nearItems[0] ? nearItems[0].width + 10 : 200;

    const aircraftText = await this.getTextFromRectangle({
      left: textItem.left + textItem.width,
      top: textItem.top,
      width,
      height: textItem.height + 5,
    });
    if (!aircraftText) return this.getAircraftResult(null, null);

    const match = /\((.*)\)/.exec(aircraftText);
    const aircraft = match?.[1] ?? null;
    const aircraftType = (match ? aircraftText.replace(match[0], '').trim() : aircraftText.trim()).replace(
      /^\w+\s/,
      '',
    );
    return this.getAircraftResult(aircraft, aircraftType);
  }

  async getPrice() {
    const textItem = this.getTextItem('Total Amount');
    if (!textItem) return null;
    const left = textItem.left + textItem.width;
    const result = await this.getTextFromRectangle({
      left,
      top: textItem.top,
      width: this.canvas.width / this.scale - left - 5,
      height: textItem.height + 5,
    });
    if (!result) return null;

    const [amount, currency] = result.trim().split(' ');

    const parsedAmount = parseFloat(amount.replaceAll('.', '').replaceAll(',', '.'));
    const price: ParsedQuotePrice = {};
    if (parsedAmount > 0) price.amount = parsedAmount;
    if (currency) price.currency = currency as VictorCurrencies;
    return price;
  }

  extractAirport(text: string) {
    const res = /\((.*)\)/.exec(text);
    if (res?.[1]) return res[1].toUpperCase();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getLegHeight(legNum: number, legsTableTop: number): number {
    return this.legHeight;
  }

  getLegsCount(legsTableTop: number): number {
    const endTextItem = this.getTextItem('Net Amount');
    if (!endTextItem) return 0;
    return Math.round((endTextItem.top - legsTableTop) / this.legHeight);
  }

  async getLeg(tableHeaderTextItems: TableHeader, legNum: number, legsTableTop: number): Promise<ParsedQuoteLeg> {
    const getLegText = async (key: keyof TableHeader, endKey?: keyof TableHeader): Promise<string> =>
      this.getTextFromRectangle({
        left: tableHeaderTextItems[key]!.left - 4,
        top: legsTableTop + legNum * this.getLegHeight(legNum, legsTableTop),
        width: endKey
          ? tableHeaderTextItems[endKey]!.left - tableHeaderTextItems[key]!.left
          : tableHeaderTextItems[key]!.width + 10,
        height: this.getLegHeight(legNum, legsTableTop),
      });

    const [deptAirportText, arrAirportText, deptDateText, deptTimeText, durationText, passengersText] =
      await Promise.all([
        getLegText('from', 'departure'),
        getLegText('to', 'arrival'),
        getLegText('date', 'flight'),
        getLegText('departure', 'to'),
        getLegText('duration', 'pax'),
        getLegText('pax'),
      ]);

    const deptAirportCode = this.extractAirport(deptAirportText);
    const arrAirportCode = this.extractAirport(arrAirportText);
    const airports = await this.getAirportsResult(deptAirportCode, arrAirportCode);

    const result: ParsedQuoteLeg = {
      ...airports,
      passengers: parseInt(passengersText),
    };

    const date = deptDateText.split('\n')[0];
    const timeResult = /\d{2}:\d{2}/.exec(deptTimeText);
    if (date && timeResult?.[0]) {
      result.deptDate = this.getLegDeptDate(moment(date, 'DD MMM YYYY'), timeResult[0], result.deptAirport?.timeZone);
    }

    const [hours, mins] = durationText.split('h');
    if (hours.trim()) {
      result.flightTime = parseInt(hours.trim()) * 60 + (mins.trim() ? parseInt(mins.trim()) : 0);
    }
    return result;
  }

  async getLegs() {
    let legs: ParsedQuoteLeg[] = [];
    const startTextItem = this.getTextItem('Your Itinerary');
    if (!startTextItem) return [];

    const tableHeaderTextItems: TableHeader = {
      date: this.getTextItem('Date', startTextItem.index),
      flight: this.getTextItem('Flight', startTextItem.index),
      from: this.getTextItem('From', startTextItem.index),
      departure: this.getTextItem('Departure', startTextItem.index),
      to: this.getTextItem('To', startTextItem.index),
      arrival: this.getTextItem('Arrival', startTextItem.index),
      duration: this.getTextItem('Duration', startTextItem.index),
      pax: this.getTextItem('Pax', startTextItem.index),
    };
    if (tableHeaderTextItems.date === null) return [];
    const legsTableTop = tableHeaderTextItems.date.top + 17;
    const legsCount = this.getLegsCount(legsTableTop);
    if (!legsCount) return [];

    legs = await Promise.all(
      Array.from(Array(legsCount)).map((v, i) => {
        return this.getLeg(tableHeaderTextItems, i, legsTableTop);
      }),
    );

    return legs;
  }

  async getAllowPets() {
    const amenitiesTextItem = this.getTextItem('Amenities:');
    const itineraryTextItem = this.getTextItem('Your Itinerary');
    const referenceTextItem = this.getTextItem('Reference');
    if (!amenitiesTextItem || !itineraryTextItem || !referenceTextItem) return null;

    const result = await this.getTextFromRectangle({
      left: amenitiesTextItem.left,
      top: amenitiesTextItem.top,
      width: referenceTextItem.left - amenitiesTextItem.left,
      height: itineraryTextItem.top - amenitiesTextItem.top,
    });
    if (!result) return null;
    return result.replaceAll(/\n/g, ' ').includes('Pets Allowed');
  }

  getNotesPoints(): [ParsedQuoteTextItem, ParsedQuoteTextItem] | null {
    let notesStartPoint = this.getTextItem('Remarks');
    if (!notesStartPoint) {
      const backupStartPoint = this.getTextItem('Total Amount');
      if (!backupStartPoint) return null;

      notesStartPoint = backupStartPoint;
      notesStartPoint.top = notesStartPoint.top + notesStartPoint.height;
    }
    const notesEndPoint = this.getTextItem('AirX Charter Ltd.');
    if (!notesEndPoint) return null;

    return [notesStartPoint, notesEndPoint];
  }

  async getFuelStop() {
    const notes = await this.getNotes();
    if (!notes) return null;
    return notes.includes('Fuel stop needed') ? 'required' : 'no-stop';
  }

  async getNotes() {
    const points = this.getNotesPoints();
    if (!points) return null;
    const [notesStartPoint, notesEndPoint] = points;

    const result = await this.getTextFromRectangle({
      left: 0,
      top: notesStartPoint.top,
      width: this.canvas.width - 1,
      height: notesEndPoint.top - notesStartPoint.top,
    });

    if (!result) return null;
    return result.replaceAll(/\n/g, ' ');
  }

  getWifi() {
    return null;
  }
}
