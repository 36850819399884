import moment from 'moment-timezone';
import { BaseParser } from './base';
import { VictorCurrencies } from 'fortune-client';
import { ParsedQuoteLeg, ParsedQuotePrice, ParsedQuoteTextItem } from './types';
import { TextItem } from 'pdfjs-dist/types/src/display/api';
import { QuoteWifi } from '~/components/forms/QuoteForm';

export class VistaJetParser extends BaseParser {
  scale = 2;

  async getAircraft() {
    const textItem = this.getTextItem('AIRCRAFT');
    let aircraftType = null;
    if (textItem) {
      const result = await this.getTextFromRectangle({
        left: textItem.left - 5,
        top: textItem.top + textItem.height,
        width: this.canvas.width / this.scale - textItem.left,
        height: 55,
      });
      if (result) {
        aircraftType = result.split('\n')[0];
      }
    }
    return this.getAircraftResult(null, aircraftType);
  }

  async getPrice() {
    const priceTextItem = this.getTextItem('PRICE');
    if (!priceTextItem) return null;

    const result = await this.getTextFromRectangle({
      left: priceTextItem.left,
      top: priceTextItem.top + priceTextItem.height,
      width: this.canvas.width / this.scale - 70,
      height: 50,
    });
    if (!result) return null;

    const [currency, amount] = result.trim().replaceAll(',', '').split(' ');

    const parsedAmount = parseFloat(amount);
    const price: ParsedQuotePrice = {};
    if (parsedAmount > 0) price.amount = parsedAmount;
    if (currency) price.currency = currency as VictorCurrencies;
    return price;
  }

  extractAirport(text: string) {
    const firstLine = text.split(' ');
    if (firstLine.length > 1) return firstLine[firstLine.length - 1];
  }

  async getLeg(columns: ParsedQuoteTextItem[], bottom: number): Promise<ParsedQuoteLeg> {
    const getLegText = async (index: number): Promise<string> => {
      const currentItem = columns[index];
      const rightItem = columns[index + 1];
      return this.getTextFromRectangle({
        left: currentItem.left - 5,
        top: currentItem.top - 5,
        width: rightItem.left - currentItem.left,
        height: bottom - currentItem.top,
      });
    };

    const deptAirportCode = this.extractAirport(columns[1].str);
    const arrAirportCode = this.extractAirport(columns[2].str);
    const airports = await this.getAirportsResult(deptAirportCode, arrAirportCode);

    const result: ParsedQuoteLeg = {
      ...airports,
      passengers: parseInt(columns[4].str),
    };

    const deptDateText = await getLegText(0);
    result.deptDate = this.getLegDeptDate(
      moment(deptDateText.replace('\n', ''), 'MMM D, YYYY'),
      columns[3].str.replace('loc.', '').trim(),
      result.deptAirport?.timeZone,
    );
    const timeText = await getLegText(3);
    const matches = /Flight time: (.*)/g.exec(timeText.replaceAll('\n', ''));

    if (matches?.[1]) {
      const [hours, mins] = matches[1].split(':');
      result.flightTime = parseInt(hours.trim()) * 60 + (mins.trim() ? parseInt(mins.trim()) : 0);
    }
    return result;
  }

  async getLegs() {
    this.assertInitialization();

    const aircraftItem = this.getTextItem('AIRCRAFT');
    const bottomItem = this.getTextItem('* All times are local and estimated') || this.getTextItem('PRICE');
    if (!aircraftItem || !bottomItem) return [];

    const deptDates = (this.textContent.items as TextItem[]).reduce<ParsedQuoteTextItem[]>((memo, item, index) => {
      if (index > aircraftItem.index && index < bottomItem.index && /[A-Z][a-z]{2}\s\d{1,2},/.test(item.str)) {
        memo.push(this.textItemToParsedQuoteTextItem(item, index));
      }
      return memo;
    }, []);

    return await Promise.all(
      deptDates.map((deptDate, index) => {
        return this.getLeg(
          [deptDate, ...this.getTextItemOnSameLine(deptDate)],
          deptDates[index + 1]?.top || bottomItem.top,
        );
      }),
    );
  }

  async getWifi() {
    const notes = await this.getNotes();
    return notes?.toLowerCase().includes('wifi') ? QuoteWifi.FREE : null;
  }

  getAllowPets() {
    return false;
  }

  getFuelStop() {
    return null;
  }

  async getNotes() {
    const notesItem = this.getTextItem('ADDITIONAL NOTES');
    if (notesItem) {
      let bottomItem = this.getTextItem('CANCELLATION POLICY');
      if (!bottomItem) bottomItem = this.getTextItem('VistaJet Limited, SkyParks Business Centre', 0, 'includes');
      const top = notesItem.top + notesItem.height;
      const result = await this.getTextFromRectangle({
        left: notesItem.left,
        top: top,
        width: this.canvas.width / this.scale - 70,
        height: (bottomItem?.top || this.canvas.height / this.scale) - top,
      });
      return result.replace('Thank you for your enquiry. We are pleased to give you the following offer:', '').trim();
    }
    return null;
  }
}
