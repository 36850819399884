import { useFilterStatus } from '~/views/dashboard/QuotesView/hooks/filters';
import { Autocomplete, TextField } from '@mui/material';
import { OperatorQuoteVConnectStatus } from 'fortune-client';
import { getStatusLabel, STATUSES_MAP } from '~/views/dashboard/components/QuoteStatus';

const isOperatorQuoteVConnectStatus = (status: string | null): status is OperatorQuoteVConnectStatus => {
  if (!status) return false;
  const values: OperatorQuoteVConnectStatus[] = [
    'submitted',
    'visible',
    'booked',
    'unavailable',
    'closed',
    'complete',
    'cancelled',
    'closed-lost',
  ];
  return Boolean(values.find((el) => el.toString() === status));
};

const STATUSES = Object.keys(STATUSES_MAP) as OperatorQuoteVConnectStatus[];
export const StatusFilter = () => {
  const [status, setStatus] = useFilterStatus();

  const beforeBooking: OperatorQuoteVConnectStatus[] = ['submitted', 'visible', 'unavailable', 'closed'];
  const statusValue = isOperatorQuoteVConnectStatus(status) ? status : null;

  return (
    <div>
      <Autocomplete
        value={statusValue}
        options={STATUSES.sort((a, b) => (beforeBooking.includes(b) ? 1 : -1))}
        groupBy={(option) => (beforeBooking.includes(option) ? 'REQUEST' : 'BOOKING')}
        getOptionLabel={(status) => getStatusLabel(status)}
        onChange={(e, val) => {
          setStatus(val);
        }}
        renderInput={(params) => <TextField {...params} label="Status" />}
        renderGroup={(params) => (
          <div key={params.key}>
            <div style={{ paddingLeft: '10px', fontWeight: 'bolder' }}>{params.group}</div>
            <div>{params.children}</div>
          </div>
        )}
      />
    </div>
  );
};
