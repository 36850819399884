import { AirXParser } from '~/components/forms/QuoteForm/ocr/parsers/airx';
import { ParsedQuoteTextItem } from '~/components/forms/QuoteForm/ocr/parsers/types';

export class GlockAviationParser extends AirXParser {
  legDatesTextItems: ParsedQuoteTextItem[] | null = null;

  async getAircraft() {
    const startItem = this.getTextItem('Your Aircraft');
    const textItem = this.getTextItem('Aircraft', startItem?.index);
    if (!textItem) return this.getAircraftResult(null, null);

    const aircraftText = await this.getTextFromRectangle({
      left: textItem.left + textItem.width,
      top: textItem.top,
      width: 200,
      height: textItem.height + 5,
    });
    if (!aircraftText) return this.getAircraftResult(null, null);

    const match = /\((.*)\)/.exec(aircraftText);
    const aircraft = match?.[1] ?? null;
    const aircraftType = match ? aircraftText.replace(match[0], '').trim() : aircraftText.trim();

    return this.getAircraftResult(aircraft, aircraftType);
  }

  getDatesTextItems(): ParsedQuoteTextItem[] | null {
    if (!this.legDatesTextItems) {
      const itineraryTextItem = this.getTextItem('Your Itinerary');
      if (itineraryTextItem) {
        const startTextItem = this.getTextItem('Date', itineraryTextItem.index);
        const endTextItem = this.getTextItem('Net Amount');
        if (startTextItem && endTextItem) {
          this.legDatesTextItems = this.getTextItemOnSameColumn(startTextItem, endTextItem);
        }
      }
    }
    return this.legDatesTextItems;
  }

  getLegHeight(legNum: number, legsTableTop: number) {
    const textItems = this.getDatesTextItems();
    if (!textItems) return this.legHeight;
    if (legNum === 0) {
      if (!textItems[legNum]) return this.legHeight;
      return (textItems[legNum].top + textItems[legNum].height / 2 - legsTableTop) * 2;
    }
    if (!textItems[legNum - 1] || !textItems[legNum]) return this.legHeight;
    return textItems[legNum].top - textItems[legNum - 1].top;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getLegsCount(legsTableTop: number): number {
    const textItems = this.getDatesTextItems();
    return textItems?.length ?? 0;
  }

  async getAllowPets() {
    return Promise.resolve(null);
  }

  async getFuelStop() {
    return Promise.resolve(null);
  }

  async getNotes() {
    const notesStartPoint = this.getTextItem('Remarks');
    if (!notesStartPoint) return null;
    let notesEndPoint = this.getTextItem('Terms and Conditions');
    if (!notesEndPoint) {
      notesEndPoint = this.getTextItem('GLOCK Aviation GmbH');
    }
    if (!notesEndPoint) return null;

    const top = notesStartPoint.top + notesStartPoint.height;
    const result = await this.getTextFromRectangle({
      left: 0,
      top,
      width: this.canvas.width - 1,
      height: notesEndPoint.top - top,
    });

    if (!result) return null;
    return result.replaceAll(/\n/g, ' ');
  }
}
