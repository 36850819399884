import React from 'react';

import { Box, SxProps } from '@mui/material';
import DateRangePicker from './DateRangePicker';

import { DateRange, DefinedRange } from '../types';
import { Locale } from 'date-fns';

export interface DateRangePickerWrapperProps {
  open: boolean;
  toggle: () => void;
  value?: DateRange;
  definedRanges?: DefinedRange[];
  minDate?: Date | string;
  maxDate?: Date | string;

  onChange: (dateRange: DateRange) => void;
  closeOnClickOutside?: boolean;
  wrapperSx?: SxProps;
  locale?: Locale;
}

const DateRangePickerWrapper: React.FunctionComponent<DateRangePickerWrapperProps> = (
  props: DateRangePickerWrapperProps,
) => {
  const { closeOnClickOutside, wrapperSx, toggle, open } = props;

  const handleToggle = () => {
    if (closeOnClickOutside === false) {
      return;
    }

    toggle();
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {open && (
        <Box
          sx={{
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            bottom: 0,
            zIndex: 0,
            right: 0,
            left: 0,
            top: 0,
          }}
          onKeyDown={(event) => event.key === 'Escape' && handleToggle()}
          onClick={handleToggle}
        />
      )}

      <Box sx={{ position: 'relative', zIndex: 9999, ...wrapperSx }}>
        <DateRangePicker {...props} />
      </Box>
    </Box>
  );
};

export default DateRangePickerWrapper;
