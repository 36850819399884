import moment from 'moment-timezone';
import { BaseParser } from './base';
import { VictorCurrencies } from 'fortune-client';
import { ParsedQuoteLeg, ParsedQuotePrice, ParsedQuoteTextItem } from './types';
import { TextItem } from 'pdfjs-dist/types/src/display/api';

export class SkysideParser extends BaseParser {
  scale = 2;

  async getTextAfter(label: string): Promise<string | null> {
    const textItem = this.getTextItem(label);
    let result = null;
    if (textItem) {
      const nearItems = this.getTextItemOnSameLine(textItem);
      const width = nearItems[0] ? nearItems[0].width + 10 : 200;

      result = await this.getTextFromRectangle({
        left: textItem.left + textItem.width,
        top: textItem.top,
        width,
        height: textItem.height + 5,
      });
    }
    return result;
  }

  async getAircraft() {
    const aircraft = await this.getTextAfter('REGISTRATION:');
    const aircraftType = await this.getTextAfter('AIRCRAFT TYPE:');
    return this.getAircraftResult(aircraft, aircraftType);
  }

  async getPrice() {
    const textItem = this.getTextItem('PRICE');
    if (!textItem) return null;

    const left = this.canvas.width / this.scale - 120;
    const result = await this.getTextFromRectangle({
      left,
      top: textItem.top,
      width: 100,
      height: textItem.height + 5,
    });
    if (!result) return null;

    const [currency, amount] = result.trim().split(' ');

    const parsedAmount = parseFloat(amount);
    const price: ParsedQuotePrice = {};
    if (parsedAmount > 0) price.amount = parsedAmount;
    if (currency) price.currency = currency as VictorCurrencies;
    return price;
  }

  extractAirport(text: string) {
    const res = /\((.*)\)/.exec(text);
    if (res?.[1]) return res[1].toUpperCase();
  }

  async getLeg(columns: ParsedQuoteTextItem[], bottom: number): Promise<ParsedQuoteLeg> {
    const getLegText = async (leftItem: ParsedQuoteTextItem, rightItem: ParsedQuoteTextItem): Promise<string> => {
      const left = leftItem.left + leftItem.width;
      return this.getTextFromRectangle({
        left,
        top: leftItem.top,
        width: rightItem.left - left,
        height: bottom - leftItem.top,
      });
    };
    const deptAirportText = await getLegText(columns[0], columns[2]);
    const arrAirportText = await getLegText(columns[2], columns[4]);
    const deptAirportCode = this.extractAirport(deptAirportText);
    const arrAirportCode = this.extractAirport(arrAirportText);
    const airports = await this.getAirportsResult(deptAirportCode, arrAirportCode);

    const result: ParsedQuoteLeg = {
      ...airports,
      passengers: parseInt(columns[6].str),
    };

    result.deptDate = this.getLegDeptDate(
      moment(columns[0].str, 'DD-MM-YYYY'),
      columns[2].str,
      result.deptAirport?.timeZone,
    );

    const [hours, mins] = columns[5].str.split(':');
    if (hours.trim()) {
      result.flightTime = parseInt(hours.trim()) * 60 + (mins.trim() ? parseInt(mins.trim()) : 0);
    }
    return result;
  }

  async getLegs() {
    this.assertInitialization();

    const pax = this.getTextItem('PAX');
    const price = this.getTextItem('PRICE');
    if (!pax || !price) return [];

    const deptDates = (this.textContent.items as TextItem[]).reduce<ParsedQuoteTextItem[]>((memo, item, index) => {
      if (index > pax.index && index < price.index && /\d\d-\d\d-\d\d\d\d/.test(item.str)) {
        memo.push(this.textItemToParsedQuoteTextItem(item, index));
      }
      return memo;
    }, []);
    return await Promise.all(
      deptDates.map((deptDate, index) => {
        return this.getLeg(
          [deptDate, ...this.getTextItemOnSameLine(deptDate)],
          deptDates[index + 1]?.top || price.top - 10,
        );
      }),
    );
  }

  getAllowPets() {
    return false;
  }

  getFuelStop() {
    return null;
  }

  getNotes() {
    return null;
  }

  getWifi() {
    return null;
  }
}
